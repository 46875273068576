// // 搜索类型的 Select 下拉框列表
const orderFinishList = [
  {
    value: "未完工",
    label: "未完工",
  },
  {
    value: "完工",
    label: "完工",
  },
];

const greyclothFinishList = [
  {
    value: "未完工",
    label: "未完工",
  },
  {
    value: "完工",
    label: "完工",
  },
];

export {
  orderFinishList, greyclothFinishList
}
