<template>
  <div id="page-grey-cloth-sheet" class="container">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <span style="margin-right: 10px">客户</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.client"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">合同号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.contractNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">客户品号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.clientProductName"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">坯布货号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.greyClothGoodsNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">颜色确认</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.colorConfirm"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">交货日期</span>
        <DatePicker
          style="width: 220px"
          type="daterange"
          placeholder="请选择日期范围"
          :clearable="false"
          v-model="listParam.filterDateRange"
          @on-change="handleDateRangeChange"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">整单是否完工</span>
        <Select v-model="listParam.orderFinish" style="width: 120px" @on-change="getTableListData">
          <Option v-for="(item, index) in orderFinishList" :value="item.value" :key="index">
            {{ item.value }}</Option
          >
        </Select>
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">坯布是否完工</span>
        <Select
          v-model="listParam.greyclothFinish"
          style="width: 120px"
          @on-change="getTableListData"
        >
          <Option v-for="(item, index) in greyclothFinishList" :value="item.value" :key="index">
            {{ item.value }}</Option
          >
        </Select>
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">理单</span>
        <Select v-model="listParam.arrangeOrder" style="width: 120px" @on-change="getTableListData">
          <Option value="ALL" key="ALL">全部</Option>
          <Option v-for="(item, index) in arrangeOrderList" :value="item" :key="index">
            {{ item }}</Option
          >
        </Select>
      </div>
    </div>
    <div class="common-action-block">
      <div class="common-action-item" @click="refresh">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="exportTableList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
      <!-- <div class="common-action-item" @click="syncTableData">
        <i class="iconfont iconicon-tongbu"></i>
        同步
      </div> -->
    </div>

    <!-- 表格 -->
    <Table border :loading="loading" :columns="tableColumns" :data="tableData">
      <template slot-scope="{ row, index }" slot="operation">
        <span style="color: #256de6; cursor: pointer;" @click="edit(row, index)">编辑</span>
      </template>
    </Table>

    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />

    <!-- 编辑弹出框 -->
    <Modal class="operationModal" v-model="operationModal" title="编辑" @on-ok="ok">
      <Row>
        <Col span="7">坯布织造厂:</Col>
        <Col span="17"><Input v-model="factory" style="width: 300px"/></Col>
      </Row>
      <Row>
        <Col span="7">日期:</Col>
        <Col span="17">
          <DatePicker v-model="date" type="date" style="width: 300px"></DatePicker
        ></Col>
      </Row>
      <Row>
        <Col span="7">颜色确认:</Col>
        <Col span="17"><Input v-model="colorConfirm" style="width: 300px"/></Col>
      </Row>
      <Row>
        <Col span="7">预计完成时间:</Col>
        <Col span="17"
          ><DatePicker v-model="predictFinishDate" type="date" style="width: 300px"></DatePicker
        ></Col>
      </Row>
      <Row>
        <Col span="7">染色定型出厂时间:</Col>
        <Col span="17"
          ><DatePicker
            v-model="dyeingAndSettingFactoryTime"
            type="date"
            style="width: 300px"
          ></DatePicker
        ></Col>
      </Row>
      <Row>
        <Col span="7">异常:</Col>
        <Col span="17"><Input v-model="abnormal" style="width: 300px"/></Col>
      </Row>
      <Row>
        <Col span="7">整单是否完工:</Col>
        <Col span="17">
          <Select v-model="orderFinish" style="width: 120px">
            <Option v-for="(item, index) in orderFinishList" :value="item.value" :key="index">
              {{ item.value }}</Option
            >
          </Select></Col
        >
      </Row>
      <Row>
        <Col span="7">理单:</Col>
        <Col span="17">
          <Select v-model="arrangeOrder" style="width: 120px">
            <Option v-for="(item, index) in arrangeOrderList" :value="item" :key="index">
              {{ item }}</Option
            >
          </Select></Col
        >
      </Row>
    </Modal>
  </div>
</template>

<script>
import { fetchSheetReportList } from "./api";
import { orderFinishList, greyclothFinishList } from "./constant";
export default {
  data() {
    return {
      // 弹窗接口参数
      ztopId: -1, // 订单进度表id，没有就传-1
      date: "", // 日期
      contractNo: "", // 合同号
      greyClothGoodsNo: "", // 坯布货号
      factory: "", // 坯布制造厂
      colorConfirm: "", // 颜色确认
      predictFinishDate: "", // 预计完成时间
      dyeingAndSettingFactoryTime: "", // 染色定型出厂时间
      abnormal: "", // 异常
      orderFinish: "未完工", // 整单是否完工,完工、未完工，默认未完工
      arrangeOrder: "", // 理单,筛选，后端回传一个理单列表
      remark: "", // 备注

      value: "",
      operationModal: false,
      // 首页表格配置
      loading: false,
      listParam: {
        // startDate: this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"), // 如 '2021-07-16'，精确到天
        // endDate: this.moment(new Date()).format("YYYY-MM-DD"),
        filterDateRange: ["", ""], // 前端用，

        client: "", // 客户
        contractNo: "", // 合同号
        clientProductName: "", // 客户品号
        greyClothGoodsNo: "", // 坯布货号
        colorConfirm: "", // 颜色确认
        deviveryDateStart: "", // 交货日期（开始）
        deviveryDateEnd: "", // 交货日期（结束）
        orderFinish: "未完工", // 整单是否完工,完工、未完工，默认未完工
        greyclothFinish: "未完工", // 坯布是否完工,完工、未完工，默认未完工
        arrangeOrder: "ALL", // 理单,筛选，回传一个理单列表
        pageNum: 1,
        pageSize: 10
      },

      tableData: [],
      tableColumns: [
        {
          title: "操作",
          minWidth: 80,
          fixed: "left",
          slot: "operation"
        },
        // {
        //   title: "序号",
        //   type: "index",
        //   minWidth: 80,
        //   fixed: "left"
        // },
        {
          title: "客户",
          key: "client",
          minWidth: 180,
          fixed: "left"
        },
        {
          title: "P.O.NO",
          key: "contractNo",
          minWidth: 150,
          fixed: "left"
        },
        {
          title: "客户品名",
          key: "clientProductName",
          minWidth: 160,
          fixed: "left"
        },
        {
          title: "坯布编号",
          key: "greyClothGoodsNo",
          minWidth: 120,
          fixed: "left"
        },
        {
          title: "门幅(CM)",
          key: "doorWidth",
          minWidth: 160
        },
        {
          title: "克重(GSM)",
          key: "gramWeight",
          minWidth: 160
        },
        {
          title: "客户颜色",
          key: "clientColor",
          minWidth: 120
        },
        {
          title: "机型",
          key: "deviceType",
          minWidth: 120
        },
        {
          title: "订单数量(KG)",
          key: "orderNum",
          minWidth: 150
        },
        {
          title: "条距",
          key: "stripDistance",
          minWidth: 150
        },
        {
          title: "交货日期",
          key: "deviveryDate",
          minWidth: 120
        },
        {
          title: "颜色确认",
          key: "colorConfirm",
          minWidth: 120
        },
        {
          title: "异常",
          key: "abnormal",
          minWidth: 120
        },
        {
          title: "织厂交货统计",
          align: "center",
          children: [
            {
              title: "坯布织造厂",
              key: "factory",
              width: 180
            },
            {
              title: "日期",
              key: "date",
              width: 150
            },
            {
              title: "匹数",
              key: "pieceNum",
              width: 150
            },
            {
              title: "计划数(KG)",
              key: "planNum",
              width: 150
            }
          ]
        },
        {
          title: "匹重(KG)",
          key: "pieceWeight",
          minWidth: 120
        },
        {
          title: "染纱/染色完成情况",
          align: "center",
          children: [
            {
              title: "预计完成时间",
              key: "predictFinishDate",
              width: 150
            },
            {
              title: "投纱数量/KG",
              key: "yarnsNum",
              width: 150
            },
            {
              title: "到仓数量/KG",
              key: "arrivalQuantity",
              width: 150
            },
            {
              title: "染纱损耗率",
              key: "yarnLossRate",
              width: 150
            }
          ]
        },
        {
          title: "坯布完成情况",
          align: "center",
          children: [
            {
              title: "预计坯布完成时间",
              key: "predictGreyclothFinishDate",
              width: 150
            },
            {
              title: "是否完成",
              key: "finishStatus",
              width: 150
            },
            {
              title: "日产量（大约）",
              key: "dayProduceNum",
              width: 150
            },
            {
              title: "计划数和实际完成总数差额",
              key: "difference",
              width: 150
            },
            {
              title: "使用机台编号",
              key: "machineNo",
              width: 150
            }
          ]
        },
        {
          title: "成品统计",
          align: "center",
          children: [
            {
              title: "投染匹数",
              key: "dyeingPieceNum",
              width: 150
            },
            {
              title: "投染公斤数",
              key: "dyeingKilogramNum",
              width: 150
            },
            {
              title: "染整损耗率",
              key: "dyeingLossRate",
              width: 150
            },
            {
              title: "染色定型出厂时间",
              key: "dyeingAndSettingFactoryTime",
              width: 150
            },
            {
              title: "成品出厂匹数",
              key: "finishedProductOutPieceNum",
              width: 150
            },
            {
              title: "成品出厂公斤数",
              key: "finishedProductOutKGNum",
              width: 150
            },
            {
              title: "实际成品数",
              key: "factFinishedProductNum",
              width: 150
            },
            {
              title: "成品损耗率",
              key: "finishedLossRate",
              width: 150
            }
          ]
        },
        {
          title: "总计损耗率",
          key: "totalLossRate",
          minWidth: 120
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 120
        },
        {
          title: "整单是否完工",
          key: "orderFinish",
          minWidth: 150
        }
      ],
      orderFinishList, // 首页——搜索类型的 Select 下拉框列表
      greyclothFinishList,
      arrangeOrderList: [], // 理单下拉框数据
      listDataTotal: 0 // 分页器数据总数
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    handleDateRangeChange(formatDate, DateObj) {
      this.listParam.deviveryDateStart = formatDate[0];
      this.listParam.deviveryDateEnd = formatDate[1];

      this.getTableListData();
    },
    refresh() {
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    // handleDateChange(formatDate, DateObj) {
    //   this.listParam.deliveryDate = formatDate;
    //   this.getTableListData();
    // },
    edit(row, index) {
      console.log(row, "............row");
      this.ztopId = row.ztopId; // 订单进度表id，没有就传-1 现在好像拿不到
      this.date = row.date; // 日期
      this.contractNo = row.contractNo; // 合同号
      this.greyClothGoodsNo = row.greyClothGoodsNo; // 坯布货号
      this.factory = row.factory; // 坯布制造厂
      this.colorConfirm = row.colorConfirm; // 颜色确认
      this.predictFinishDate = row.predictFinishDate; // 预计完成时间
      this.dyeingAndSettingFactoryTime = row.dyeingAndSettingFactoryTime; // 染色定型出厂时间
      this.abnormal = row.abnormal; // 异常
      this.orderFinish = row.orderFinish; // 整单是否完工,完工、未完工，默认未完工
      this.arrangeOrder = ""; // 理单,筛选，后端回传一个理单列表
      this.operationModal = true;
    },

    ok() {
      this.editModal();
    },

    getTableListData() {
      const { filterDateRange, ...reqParamObj } = this.listParam;
      this.loading = true;
      fetchSheetReportList({ ...reqParamObj })
        .then(res => {
          if (res.data.success === 1) {
            const { list } = res.data.body;
            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.arrangeOrderList = res.data.body.arrangeOrderList; // 理单数组
            this.loading = false;
          } else {
            this.tableData = [];
            this.listDataTotal = 0;
            this.$Message.error(res.data.msg);
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 修改弹框数据
    editModal() {
      if (this.date !== "") {
        this.date = this.moment(this.date).format("YYYY-MM-DD");
      }
      if (this.predictFinishDate !== "") {
        this.predictFinishDate = this.moment(this.predictFinishDate).format("YYYY-MM-DD");
      }
      if (this.dyeingAndSettingFactoryTime !== "") {
        this.dyeingAndSettingFactoryTime = this.moment(this.dyeingAndSettingFactoryTime).format(
          "YYYY-MM-DD"
        );
      }
      const params = {
        ztopId: this.ztopId, // 订单进度表id，没有就传-1
        date: this.date, // 日期
        contractNo: this.contractNo, // 合同号
        greyClothGoodsNo: this.greyClothGoodsNo, // 坯布货号
        factory: this.factory, // 坯布制造厂
        colorConfirm: this.colorConfirm, // 颜色确认
        predictFinishDate: this.predictFinishDate, // 预计完成时间
        dyeingAndSettingFactoryTime: this.dyeingAndSettingFactoryTime, // 染色定型出厂时间
        abnormal: this.abnormal, // 异常
        orderFinish: this.orderFinish, // 整单是否完工,完工、未完工，默认未完工
        arrangeOrder: this.arrangeOrder, // 理单,筛选，后端回传一个理单列表
        remark: this.remark // 备注
      };
      this.axios({
        url: "/dtsum/zongtong/report/statisticalFormsController/saveAndUpdate",
        method: "post",
        data: params
      })
        .then(res => {
          if (res.data.success === 1) {
            this.getTableListData();
            this.$Message.info("修改成功");
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
      // editModal(params)
      //   .then(res => {
      //     if (res.data.success === 1) {
      //       this.getTableListData();
      //       this.$Message.info("修改成功");
      //     } else {
      //       this.$Message.error(res.data.msg);
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },

    // 同步表格数据
    // syncTableData() {
    //   this.loading = true;
    //   this.axios({
    //     url: "/dtsum/zongtong/greycloth/greyClothController/synchronizeBadClothInquire",
    //     method: "get"
    //   })
    //     .then(res => {
    //       if (res.data.success === 1) {
    //         this.$Message.success("同步成功！");
    //         this.getTableListData();
    //       } else {
    //         this.$Message.warning("同步失败！");
    //       }
    //       this.loading = false;
    //     })
    //     .catch(err => {
    //       console.log(err);
    //     });
    // },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },
    exportTableList() {
      const { pageNum, pageSize, filterDateRange, ...otherParam } = this.listParam;
      const dataStr = this.qs.stringify(otherParam);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/report/statisticalFormsController/orderProgressListExport?${dataStr}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.container {
}

.operationModal {
  .ivu-row {
    height: 50px;
    line-height: 50px;
  }
}
</style>
