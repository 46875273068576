import axios from "axios";

/**
 * 获取订单进度报表列表
 */
 export const fetchSheetReportList = ({ pageNum, pageSize, ...param }) => {
    return axios({
      url: "/dtsum/zongtong/report/statisticalFormsController/orderProgressList",
      method: "GET",
      params: {
        pageNum,
        pageSize,
        param
      }
    });
  };


